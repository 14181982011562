<template>
  <table-overview
    title="Reject Reasons"
    store="rejectReasons"
    :headers="[
      { text: 'Name', value: 'name' },
      { text: 'Mail template', value: 'mail_template', sortable: false },
      {
        text: 'Details',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'reject_reasons_view'
      }
    ]"
    :actions="actions"
    searchable
    force-initial-sort
  >
    <reject-reason-table-row
      slot="table-row"
      slot-scope="{ item }"
      :reject-reason-id="item"
    />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import rejectReasonsModule from "@/store/modules/reject-reasons";
import RejectReasonTableRow from "../../components/reject-reason/RejectReasonTableRow";
import { mapOverviewLifecycleMethods } from "../helpers";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "rejectReasons",
  rejectReasonsModule
);

export default {
  name: "reject-reasons-view",
  components: {
    RejectReasonTableRow,
    TableOverview
  },
  data: () => ({
    actions: [
      {
        icon: "far fa-fw fa-plus",
        color: "pink",
        to: { name: "reject-reasons.create" },
        rolesOrPermissions: "reject_reason_create"
      }
    ]
  }),
  ...overviewLifecycleMethods
};
</script>
