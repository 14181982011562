var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:255'),expression:"'required|max:255'"}],attrs:{"label":"Name","counter":255,"error-messages":_vm.errors.collect('name'),"data-vv-name":"name","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('max:255'),expression:"'max:255'"}],attrs:{"label":"Mail template","counter":255,"error-messages":_vm.errors.collect('mailTemplate'),"data-vv-name":"mailTemplate"},model:{value:(_vm.mailTemplate),callback:function ($$v) {_vm.mailTemplate=$$v},expression:"mailTemplate"}}),_c('v-checkbox',{attrs:{"color":"primary","label":"Soft rejectable","error-messages":_vm.errors.collect('isSoftRejectable')},model:{value:(_vm.isSoftRejectable),callback:function ($$v) {_vm.isSoftRejectable=$$v},expression:"isSoftRejectable"}}),_c('v-checkbox',{attrs:{"color":"primary","label":"Soft reject customizable","error-messages":_vm.errors.collect('isSoftRejectCustomizable')},model:{value:(_vm.isSoftRejectCustomizable),callback:function ($$v) {_vm.isSoftRejectCustomizable=$$v},expression:"isSoftRejectCustomizable"}}),(_vm.isSoftRejectCustomizable)?_c('v-locale-textarea',{attrs:{"always-visible-locales":[
      'nl_be',
      'nl_nl',
      'fr_be',
      'fr_fr',
      'de_de',
      'en_gb'
    ],"required-locales":['nl_be', 'nl_nl', 'fr_be', 'fr_fr'],"error-messages":_vm.errors.collect('softRejectCustomization')},model:{value:(_vm.softRejectCustomization),callback:function ($$v) {_vm.softRejectCustomization=$$v},expression:"softRejectCustomization"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }