<template>
  <v-container fluid>
    <v-text-field
      v-model="name"
      v-validate="'required|max:255'"
      label="Name"
      :counter="255"
      :error-messages="errors.collect('name')"
      data-vv-name="name"
      required
    />
    <v-text-field
      v-model="mailTemplate"
      v-validate="'max:255'"
      label="Mail template"
      :counter="255"
      :error-messages="errors.collect('mailTemplate')"
      data-vv-name="mailTemplate"
    />
    <v-checkbox
      v-model="isSoftRejectable"
      color="primary"
      label="Soft rejectable"
      :error-messages="errors.collect('isSoftRejectable')"
    />
    <v-checkbox
      v-model="isSoftRejectCustomizable"
      color="primary"
      label="Soft reject customizable"
      :error-messages="errors.collect('isSoftRejectCustomizable')"
    />
    <v-locale-textarea
      v-model="softRejectCustomization"
      v-if="isSoftRejectCustomizable"
      :always-visible-locales="[
        'nl_be',
        'nl_nl',
        'fr_be',
        'fr_fr',
        'de_de',
        'en_gb'
      ]"
      :required-locales="['nl_be', 'nl_nl', 'fr_be', 'fr_fr']"
      :error-messages="errors.collect('softRejectCustomization')"
    />
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import VLocaleTextarea from "@/components/generic/input/VLocaleTextarea";

const { mapComputed } = createNamespacedHelpers("rejectReason");

export default {
  name: "reject-reason",
  inject: ["$validator"],
  components: { VLocaleTextarea },
  data: () => ({}),
  computed: {
    ...mapComputed([
      "name",
      "mailTemplate",
      "isSoftRejectable",
      "isSoftRejectCustomizable",
      "softRejectCustomization"
    ])
  }
};
</script>
