var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"white"}},[_c('crud-header',{attrs:{"title":_vm.name,"type":"Reject reason","store":"rejectReason"},on:{"save-success":function (ref) {
                var id = ref.id;

                return _vm.$router.push({
          name: 'reject-reasons.show',
          params: { rejectReasonId: id }
        });
}}}),_c('reject-reason')],1)}
var staticRenderFns = []

export { render, staticRenderFns }