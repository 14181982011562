<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Reject reason"
      store="rejectReason"
      @save-success="
        ({ id }) =>
          $router.push({
            name: 'reject-reasons.show',
            params: { rejectReasonId: id }
          })
      "
    />
    <reject-reason />
  </v-card>
</template>

<script type="text/babel">
import rejectReasonModule from "@/store/modules/reject-reason";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapCreateViewLifecycleMethods } from "../helpers";
import RejectReason from "../../components/reject-reason/RejectReason";

const { mapGetters } = createNamespacedHelpers("rejectReason");

const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "rejectReason",
  rejectReasonModule
);

export default {
  name: "website-create-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    RejectReason,
    CrudHeader
  },
  computed: {
    ...mapGetters(["name"])
  },
  ...createViewLifecycleMethods
};
</script>
