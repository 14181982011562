<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Reject Reason"
      store="rejectReason"
      soft-delete
      :deletable="hasRolesOrPermissions('reject_reason_delete')"
      @delete-success="$router.push({ name: 'reject-reasons' })"
    />
    <reject-reason />
  </v-card>
</template>

<script type="text/babel">
import rejectReasonModule from "@/store/modules/reject-reason";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapDetailViewLifecycleMethods } from "../helpers";
import RejectReason from "../../components/reject-reason/RejectReason";

const { mapGetters } = createNamespacedHelpers("rejectReason");

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "rejectReason",
  rejectReasonModule
);

export default {
  name: "reject-reason-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    RejectReason,
    CrudHeader
  },
  computed: {
    ...mapGetters(["name"])
  },
  methods: {
    hasRolesOrPermissions(permission) {
      return this.$store.getters["auth/hasRolesOrPermissions"](permission);
    }
  },
  ...detailViewLifecycleMethods
};
</script>
